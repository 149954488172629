
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body{
  background-color: #FFF;
  font-family: "Open Sans", sans-serif;
  background-image: url(../img/bg_body.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
}

.container{
  max-width:890px;
}

.Header{
  padding-top:90px;
}

.Logo{
  padding-bottom:15px;
  float: left;
}

.Locations{
  float: right;
  color: #005991;
  font-size: 16px;
  text-align: right;
  ul{
    margin:0px;
    padding:0px;
    li{
      margin:0px;
      padding:0px;
      list-style-type:none;
      font-weight:normal;
    }
  }
}

.Navigation{
  background-color: rgba(36, 108, 183, 0.6);
  margin-bottom:10px;
  ul.nav{
      border:none !important;
    li{
      a{
        color:#FFF;
        background-color:transparent;
        margin:0px !important;
        border:none !important;
        &:hover{
          color:#FFF;
          background-color:rgba(36, 108, 183, 0.8);
          border:none !important;
          margin:0px !important;
        }
      }
    }
  }
}

.Content{
  padding: 15px;
  font-size: 16px;
  color: #005991;
  background-color: rgba(255, 255, 255, 0.6);
}

h1{
  font-size: 28px;
  font-weight: bold;
  display: block;
  color: #005991;
}

h2{
  font-size: 24px;
  font-weight: bold;
  display: block;
  color: #005991;
}

h3{
  font-size: 20px;
  font-weight: bold;
  display: block;
  color: #005991;
}

.Footer{
  padding:20px 0px 20px 0px;
  text-align:center;
  font-size:11px;
  h3{
    font-size: 15px;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
    padding: 0px;
    line-height: normal;
    color: #005991;
    text-align: center;
    letter-spacing: 3px;
  }
}

.payment-errors{
  padding:10px 0px;
  color:rgb(255, 74, 74);
  font-weight: bold;
}

@media print {
  .Navigation{
    display:none;
  }
  .hidden-print{
    display:none;
  }
  a[href]:after {
    content: none;
  }
}